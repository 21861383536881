.activate-sim-manage-page {
  .tag-approve {
    background: linear-gradient(
        0deg,
        rgba(0, 186, 52, 0.1),
        rgba(0, 186, 52, 0.1)
      ),
      #ffffff;
    color: #00ba34;
  }

  .btn-action-auto-assign{
    height: 40px;
    padding: 8px 12px;
    border-radius: 8px;
    background: var(--light-green-base, #00BA34);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);

    &:hover{
      background: var(--light-green-hover, #00BA34);
      opacity: .8;
    }
  }

  .btn-create-active-esim {
    background: #FFFFFF !important;
    border: 1px solid #E8E8E8 !important;
    box-shadow: 0px 1px 1px 0px #0000000F;
    color: #1C1C1C !important;
    font-weight: 600;
  }

  .filter-btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    padding: 0px 12px;
    p {
       margin-left: 8px !important;
       margin: 0;
       font-weight: 600;
       font-size: 16px;
    }
  }
}

.drawer-activate-sim-manage {
  .owner-info-title {
    margin-top: 24px;
  }
  .titile {
    margin-top: 32px;
  }
  .reason {
    padding: 16px;
    border-radius: 8px;
    background: #F6F6F6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #585757;
    margin-top: 24px;
  }
  .passport {
    margin-left: 0;
    margin-right: 10px;
    .multi-passport {
      display: flex;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
    }
  }
  .btn-download-all {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    background: #fff;
    color: #1c1c1c;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .tag-approve {
    background: linear-gradient(
        0deg,
        rgba(0, 186, 52, 0.1),
        rgba(0, 186, 52, 0.1)
      ),
      #ffffff;
    color: #00ba34;
  }
  .activate-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .activate-menu-left {
    display: flex;
    align-items: center;
  }

  .activate-menu-text {
    color: #1c1c1c;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-right: 16px;
    display: flex;
  }

  .activate-menu-code {
    color: #00ba34;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-left: 12px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .activate-sim-action {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .action-btn {
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
  }

  .approve {
    background: #00ba34;
  }

  .reject {
    background: #e92c2c;
  }

  .archived {
    background: #f7f7f7;
    color: #969696;
  }

  .owner-info,
  .ticket-info,
  .owner-attachments {
    // padding-left: 24px;
  }

  .ticket-info{
    margin-top: 24px;
  }

  .ticket-info-right{
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .select-handler{
    // position: absolute;
    // top: 28px;
    // left: 0;
  }
}

.modal-activate-sim {
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    align-items: flex-start;
    border: none;
  }

  .ant-modal-close {
    display: none;
  }

  .modal-title {
    color: #1c1c1c;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .modal-description {
    color: #585757;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .ant-modal-footer {
    margin-top: 0;
  }

  .ant-btn-default {
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .ant-btn-primary {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.modal-activate-sim.modal-approve {
  .ant-btn-primary:not([disabled]) {
    background: #00ba34;
    //border: none;
    font-weight: 400;
  }
}

.modal-activate-sim.modal-reject {
  .ant-btn-primary:not([disabled]) {
    background: #e92c2c;
    //border: none;
    font-weight: 400;
  }
}

.modal-select-handler{
  .modal-title{
    color: var(--light-grayscale-100, #1C1C1C);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      /* 133.333% */
      margin-bottom: 24px;
  }

  .search-cont {
    width: 100%;
    margin-bottom: 16px;
  }

  .ant-btn-primary{
    background: var(--light-green-base, #00BA34);
    border: none;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    &:hover{
      background: var(--light-green-hover, #00BA34);
      opacity: .8;
    }

    .ant-btn-default{
      height: 40px;
      padding-left: 12px;
      padding-right: 12px;
      &:hover{
        border: 1px solid #d9d9d9 !important;
        opacity: .8;
      }
    }
  }
}
