.package-manage-page {
  .package-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
      0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .package-item-left {
    margin-right: 32px;
    width: 192px;
    height: 108px;

    // height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px 0 0 12px;
    }
  }

  .package-item-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px 0;
  }

  .package-item-title {
    display: flex;
    margin-bottom: 8px;
  }

  .title {
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #1c1c1c;
    margin-right: 12px;
  }

  .package-tag {
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .tag-avaiable {
    background: #00ba341a;
    color: #00ba34;

  }



  .price {
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: #e92c2c;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .package-price {
    display: flex;
    align-items: center;
    margin-right: 20px;
    gap: 12px;
  }

  .price-item {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 40px;
    background: var(--light-background-secondary, #F6F6F6);
    color: var(--light-grayscale-200, #585757);
    font-size: 16px;
    font-weight: 400;
  }

}

.packages-drawer-page {

  .product-description-title {
    color: #585757;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .quill {
    border-radius: 8px;
    position: relative;
    //overflow: hidden;
  }

  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
  }

  .ql-editor {
    padding-top: 68px;
  }

  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .quill-editor-disabled {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: not-allowed;
  }

  .quill-editor-disabled p {
    cursor: not-allowed;
  }

  .product-description-title {
    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-radius: 8px;
  }

  .ql-toolbar.ql-snow {
    z-index: 999;
  }

  .ql-link {
    display: none !important;
  }
}

.quill {
  border-radius: 8px;
  position: relative;
  //overflow: hidden;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
}

.ql-editor {
  padding-top: 68px !important;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.quill-editor-disabled {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: not-allowed;
}

.quill-editor-disabled p {
  cursor: not-allowed;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-radius: 8px;
}

.ql-toolbar.ql-snow {
  z-index: 999;
}

.ql-link {
  display: none !important;
}
